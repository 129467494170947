import { getCurrentUrl, exec } from 'preact-router';
import style from "../header/style.css";
import clsx from "clsx";
import {Link, Match} from "preact-router/match";


const LanguageNav = () => {
    const matches = exec(getCurrentUrl(), '/:language/:page*', {});

    return (
        <div className={style.languagenav}>
            <Link activeClassName={style.active} href={'/cn/' + matches.page}>中文</Link>
            <Link activeClassName={style.active} href={'/en/'+ matches.page}>english</Link>
        </div>
    );
};

export {LanguageNav};

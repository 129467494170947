import style from './style.css';
import Match from "preact-router/match";

const Chinese = ({children}) => (
    <Match path="/cn/:page*">
        {({matches}) =>
            (matches && <span class={style.chinese} lang="cn">{children}</span>)
        }
    </Match>
);

export {Chinese};

var debug = AFRAME.utils.debug;
var coordinates = AFRAME.utils.coordinates;

delete AFRAME.components['clickable'];

/**
 * Look-at component.
 *
 * Modifies rotation to either track another entity OR do a one-time turn towards a position
 * vector.
 *
 * If tracking an object via setting the component value via a selector, look-at will register
 * a behavior to the scene to update rotation on every tick.
 */
AFRAME.registerComponent('clickable', {
    schema: {
        event: {default: ''},
        data: {default: ''}
    },

    appendBB: function () {
        var clickObj = this.el.querySelector('.clickobj');
        if(!clickObj) {
            //add a invisible box as are performant click area
            const boundingBox = new THREE.Box3().setFromObject(this.el.object3D);
            const dimensions = new THREE.Vector3();
            const position = new THREE.Vector3();
            boundingBox.getSize(dimensions);
            boundingBox.getCenter(position);
            clickObj = document.createElement('a-box');
            clickObj.setAttribute('width', dimensions.x);
            clickObj.setAttribute('height', dimensions.y);
            clickObj.setAttribute('depth', dimensions.z);
            clickObj.setAttribute('position', this.el.object3D.worldToLocal(position).toArray().join(' '));
            clickObj.setAttribute('visible', false);
            clickObj.setAttribute('class', 'clickobj');
            this.el.appendChild(clickObj);
        }

        clickObj.addEventListener('click', ()=>{
            if(event != '' && !window.app.getShowContent()){
                this.el.emit(this.data.event, {data: this.data.data, el: this.el});
            }
        });

        clickObj.addEventListener('mouseenter', ()=>{
            if(!window.app.getShowContent()){
                this.el.emit('hoverstart');
            }
        });

        clickObj.addEventListener('mouseleave', ()=>{
            if(!window.app.getShowContent()){
                this.el.emit('hoverend');
            }
        });


        //add hover animation
        this.el.setAttribute('animation__mouseenter', {
            property: 'scale',
            startEvents: 'hoverstart',
            easing: 'easeOutCubic',
            dur: 200,
            to: '1.1 1.1 1.1'
        });
        this.el.setAttribute('animation__mouseleave', {
            property: 'scale',
            startEvents: 'hoverend',
            easing: 'easeOutCubic',
            dur: 200,
            to: '1 1 1'
        });
    },

    init: function () {
        if(this.el.tagName == "A-GLTF-MODEL"){
            this.el.addEventListener('model-loaded', this.appendBB.bind(this))
        } else {
            this.appendBB();
        }
    },
});

import './style';
import App from './components/app';
import './custom-look-controls'
import './look-at'
import './clickable'


AFRAME.utils.shouldCaptureKeyEvent = function (e) {
    return !e.metaKey;
}


export default App;

import style from "./style.css";
import {Link} from "preact-router/match";
import {exec, getCurrentUrl} from "preact-router";

const Project = ({path, names, title, details}) => {
    const matches = exec(getCurrentUrl(), '/:language/:page*', {});
    return(
        <Link activeClassName={style.active} href={'/' + matches.language + path}>
            <div>
                {title &&
                    <div className={style.names}>{names}</div>
                }
                <div className={style.projecttitle}>{(title || names)}</div>
                <div className={style.details}>{details}</div>
            </div>
        </Link>
    )
}

export default Project;

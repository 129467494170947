import {Router, route, exec, setUrl, getCurrentUrl} from 'preact-router';
import { Component } from 'preact';
import Header from './header';
import {useState, useEffect, useRef} from 'preact/hooks';

// Code-splitting is automated for `routes` directory
import Start from '../routes/Start';
import PricklyPaper from '../routes/PricklyPaper';
import XiaotianLi from '../routes/XiaotianLi';
import ChristophPlutte from '../routes/ChristophPlutte';
import NaomiRado from '../routes/NaomiRado';
import QianyangZuo from '../routes/QianyangZuo';
import TetsuroPecoraro from '../routes/TetsuroPecoraro';
import JeronimoVoss from '../routes/JeronimoVoss';
import VanessaOpokuPhilishaKay from "../routes/VanessaOpokuPhilishaKay";
import MartinStiehl from "../routes/MartinStiehl";
import About from "../routes/About";
import Contact from "../routes/Contact";
import clsx from "clsx";
import style from "./app.css";
import CloseIcon from "@mui/icons-material/Close";

const routes = [
	{ component: Start, path: '/:language/start', animation: 'start'},
	{ component: About, path: '/:language/about', animation: 'about'},
	{ component: PricklyPaper, path: '/:language/prickly_paper', animation: 'prickly_paper'},
	{ component: XiaotianLi, path: '/:language/connecting_caves',animation: 'cave_silken_web'},
	{ component: ChristophPlutte, path: '/:language/urban_landscape_of_involution', animation: 'urban_landscape'},
	{ component: QianyangZuo, path: '/:language/cave_of_the_silken_web', animation: 'pansi_cave'},
	{ component: NaomiRado, path: '/:language/meditations_against_linearity', animation: 'awc'},
	{ component: TetsuroPecoraro, path: '/:language/the_lives_of_unfamous_artists', animation: 'unfamous_artists'},
	{ component: JeronimoVoss, path: '/:language/ansible', animation: 'ansible'},
	{ component: VanessaOpokuPhilishaKay, path: '/:language/rest_in_peace_simulation', animation: 'rest_in_peace'},
	{ component: MartinStiehl, path: '/:language/escaping_velocity', animation: 'escaping_velocity'},
	{ component: Contact, path: '/:language/contact', animation: 'contact'}
];

	<Start />


const App = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [showContent, setShowContent] = useState(true);

	//pointerdown event
	useEffect(()=>{
		const videos = document.querySelectorAll('a-video');
		const listener = () => {
			setShowContent(false);

			const matches = exec(getCurrentUrl(), '/:language/:page*', {});
			route('/' + matches.language);

			videos.forEach(element => element.getAttribute('material').src.play());
		}
		document.querySelector('.a-canvas').addEventListener('pointerdown', listener);
		return () => {
			document.querySelector('.a-canvas').removeEventListener('pointerdown', listener);
		};
    });

	//route event
	useEffect(()=>{
		const listener = (e) => {
			const matches = exec(getCurrentUrl(), '/:language/:page*', {});
			route('/' + matches.language + e.detail.data);
		}
		document.querySelector('a-scene').addEventListener('route', listener);
		return () => {
			document.querySelector('a-scene').removeEventListener('route', listener);
		};
	});

	//animation end event
	useEffect(()=>{
		const listener = (e) => {
			setShowContent(true);
		}
		document.querySelector('#cameraRig').addEventListener('animationcomplete', listener);
		return () => {
			document.querySelector('#cameraRig').removeEventListener('animationcomplete', listener);
		};
	});

	//acces show content state fom a-frame
	useEffect(() => {
		window.app.getShowContent = () => showContent;

		/* Unassign when component unmounts */
		return () => window.app.getShowContent = null
	})


	return (
		<div id="app">
			<Header showMenu={showMenu} setShowMenu={setShowMenu}/>
			<Content showMenu={showMenu} showContent={showContent} setShowContent={setShowContent} setShowMenu={setShowMenu}/>
		</div>
	)
}

const Content = ({showContent, setShowContent, showMenu, setShowMenu}) => {
	const cameraRef = useRef(document.getElementById('head'));
	return (
		<div className={clsx(style.content, {[style['content--hidden']]: !showContent || showMenu })}>
			<div className={style.closecontainer}>
			<button className={style.closebutton} onClick={() => {
				setShowContent(false);
				const matches = exec(getCurrentUrl(), '/:language/:page*', {});
				route('/' + matches.language);
			}}>
				<CloseIcon/>
			</button>
			</div>
			<Router onChange={(e)=>{
				routes.map((route)=>{
					if(exec(e.url, route.path, {}) !== false){
						if(route.animation){
							cameraRef.current.emit(route.animation, undefined, true);
							cameraRef.current.emit('reset', undefined, true);
						} else {
							setShowContent(true);
						}
					}

				});
				//setShowContent(true);
				setShowMenu(false);
			}}>
				<Redirect path="/" to="/en/start" />
				<None path="/:language"></None>
					{routes.map((Route, index) => (
					<Route.component path={Route.path} />
				))}
			</Router>
		</div>
	);
}

const None = () => {};

class Redirect extends Component {
	componentWillMount() {
		route(this.props.to, true);
	}

	render() {
		return null;
	}
}


export default App;

import style from './style.css';
import Match from 'preact-router/match';

const English = ({children}) => (
    <Match path="/en/:page*">
        {({matches}) =>
            (matches && <span class={style.english} lang="en">{children}</span>)
        }
    </Match>
);

export {English};

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import style from './style.css';
import clsx from 'clsx';
import Project from "./Project";
import {LanguageNav} from "../Language";

const Header = ({setShowMenu, showMenu}) => {
	const toggleMenu = () => {setShowMenu(!showMenu)};

	return (
		<header class={style.header}>
			<h1 class={style.invisible}>escaping involution</h1>
			<LanguageNav/>
			<button className={style.button} onClick={toggleMenu}>
				{showMenu ? <CloseIcon/> : <MenuIcon/>}
			</button>
			<div className={clsx(style.menu, {[style['menu--hidden']]: !showMenu})}>
				<nav>
					<Project
						path="/start"
						title="Start"
					/>
					<Project
						path="/about"
						names="HB Station & Synnika"
						title="Escaping Involution"
						details="About the Project"
					/>
					<Project
						path="/prickly_paper"
						names="Yifei Chen & Feihong Ou"
						title="Prickly Paper"
						details="28/05/2022 — 08/07/2022"
					/>
					<Project
						path="/urban_landscape_of_involution"
						names="Christoph Plutte"
						title="Urban Landscape of Involution"
						details="Published in Arts of the Working Class No.22"
					/>
					<Project
						path="/the_lives_of_unfamous_artists"
						names="Tetsuro Pecoraro"
						title="The Lives of Unfamous Artists"
						details="18/06/22 - 18/08/22"
					/>
					<Project
						path="/connecting_caves"
						names="Xiaotian Li"
						title="Connecting Caves"
						details="Published in Arts of the Working Class No.23"
					/>
					<Project
						path="/cave_of_the_silken_web"
						names="Qianyang Zuo"
						title="Cave of the Silken Web"
						details="20/07/22 - 16/09/22"
					/>
					<Project
						path="/ansible"
						names="Jeronimo Voss"
						title="Ansible"
						details="03/07/22 - 08/10/2022"
					/>
					<Project
						path="/meditations_against_linearity"
						names="Naomi Rado"
						title="Meditations against Linearity"
						details="Published in Arts of the Working Class No.25"
					/>
					<Project
						path="/rest_in_peace_simulation"
						names="Vanessa A. Opoku and Philisha Kay"
						title="Rest in Peace Simulation"
						details="Opening: Dec 28, 20:00 (CST) at Nanfei Bar"
					/>
					<Project
						path="/escaping_velocity"
						names="Martin Stiehl"
						title="Escape Velocity"
					/>
					<Project
						path="/contact"
						title="Contact"
						details="Impressum - legal notice & privacy statement"
					/>
				</nav>
			</div>
		</header>
	);
}

export default Header;
